import { ReactNode } from 'react';
import { ErrorBoundary, ErrorBoundaryProps } from 'react-error-boundary';

import { Pipeline, usePipeWithChildren, usePipeWithPayload } from '@src/contexts/PipelineContext/PipelineContext';

import StyledThemeProvider from '@src/contexts/StyledThemeContext/StyledThemeContext';
import RoutingContextProvider from '@src/contexts/RoutingContext/RoutingContext';
import LocaleContextProvider from '@src/contexts/LocaleContext/LocaleContext';
import ErrorRoute from '@src/router/error-routes';
import ErrorPage from '@pages/ErrorPage/ErrorPage';
import UsercentricsContextProvider from '@src/contexts/UsercentricsContext/UsercentricsContext';
import AdobeAnalyticsProvider from '@src/contexts/AdobeAnalytics/AdobeAnalyticsContext';

const ErrorApp = () => {
	const pipeline: ReactNode[] = [
		usePipeWithChildren(RoutingContextProvider),
		usePipeWithChildren(StyledThemeProvider),
		usePipeWithChildren(LocaleContextProvider),
		usePipeWithChildren(UsercentricsContextProvider),
		usePipeWithChildren(AdobeAnalyticsProvider),
		usePipeWithPayload<ErrorBoundaryProps>(ErrorBoundary, { FallbackComponent: ErrorPage }),
		usePipeWithChildren(ErrorRoute),
	];

	return <Pipeline components={pipeline} />;
};

export default ErrorApp;
