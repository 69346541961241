import { ReactNode } from 'react';

import { BaseErrorDisplay } from '@pages/Notification/components/BaseErrorDisplay';

interface IErrorComponents {
	BadRequest: ReactNode;
	NotFound: ReactNode;
	Unauthorized: ReactNode;
	AccessForbidden: ReactNode;
	ServerError: ReactNode;
}

export type ErrorComponentKeys = keyof IErrorComponents;

const ComponentToRender: string = atob((window?.$$context?.exception as string) ?? '');

const ErrorComponents: IErrorComponents = {
	BadRequest: <BaseErrorDisplay fieldName="badRequest" />,
	NotFound: <BaseErrorDisplay fieldName="notFound" />,
	Unauthorized: <BaseErrorDisplay fieldName="unauthorized" />,
	AccessForbidden: <BaseErrorDisplay fieldName="accessForbidden" />,
	ServerError: <BaseErrorDisplay fieldName="serverError" />,
};

const CurrentComponent = ErrorComponents[ComponentToRender as ErrorComponentKeys] ?? ErrorComponents.ServerError;

const ErrorRoute = () => CurrentComponent;

export default ErrorRoute;
