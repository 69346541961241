import '@src/utilities/tracing';
import { createRoot } from 'react-dom/client';
import '@src/utilities/yup-custom';

import ErrorApp from '@src/instances/error/ErrorApp';

const node: HTMLElement | null = document.getElementById('root') || document.createElement('div');
const root = createRoot(node);

root.render(<ErrorApp />);
